import React from 'react';
import { motion } from 'framer-motion';
import { Code, FileJson, Paintbrush, GitBranch, Package, Terminal, Settings } from 'lucide-react';

const featureData = [
  { icon: FileJson, color: "text-yellow-400", title: "TypeScript Support", description: "Easily add TypeScript to your project for enhanced type safety." },
  { icon: Code, color: "text-green-400", title: "Testing Frameworks", description: "Choose between Jest, Mocha, or Chai for robust testing." },
  { icon: Paintbrush, color: "text-pink-400", title: "Code Formatting", description: "Optionally integrate Prettier for consistent code styling." },
  { icon: Paintbrush, color: "text-purple-400", title: "CSS Preprocessors", description: "Select from plain CSS, SCSS, LESS, or Tailwind CSS." },
  { icon: GitBranch, color: "text-orange-400", title: "Routing", description: "Add React Router for seamless navigation management." },
  { icon: Package, color: "text-red-400", title: "State Management", description: "Optionally integrate Redux, MobX, or Zustand for efficient state handling." },
  { icon: GitBranch, color: "text-teal-400", title: "Git Initialization", description: "Automatically initialize a Git repository for version control." },
  { icon: Terminal, color: "text-indigo-400", title: "Package Manager Support", description: "Choose between npm, yarn, or pnpm as your package manager." },
  { icon: Settings, color: "text-cyan-400", title: "Customizable Configuration", description: "Fine-tune your project setup with a wide range of configurable options." },
];

const FeatureCard = ({ icon: Icon, color, title, description, index }) => (
  <motion.div 
    className="bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 transform hover:-translate-y-1"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5, delay: index * 0.1 }}
    whileHover={{ scale: 1.05 }}
    whileTap={{ scale: 0.95 }}
  >
    <div className="flex items-center mb-4">
      <Icon className={`w-8 h-8 mr-4 ${color}`} />
      <h3 className="text-xl font-semibold text-white">{title}</h3>
    </div>
    <p className="text-gray-300">{description}</p>
  </motion.div>
);

const Features = () => {
  return (
    <section id="features" className="py-20">
      <motion.h2 
        className="text-4xl font-bold mb-12 text-center text-blue-400"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Powerful Features
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {featureData.map((feature, index) => (
          <FeatureCard key={index} {...feature} index={index} />
        ))}
      </div>
    </section>
  );
};

export default Features;