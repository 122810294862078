import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Installation from './components/Installation';
import Testimonials from './components/Testimonials';
import DownloadChart from './components/DownloadChart';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';

const App = () => {
  const mainSections = [
    Hero,
    Features,
    Installation,
    Testimonials,
    DownloadChart,
    CallToAction
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <Header />
      <main className="max-w-6xl mx-auto px-4 py-12">
        {mainSections.map((Section, index) => (
          <Section key={index} />
        ))}
      </main>
      <Footer />
    </div>
  );
};

export default App;