import React from 'react';
import { motion } from 'framer-motion';
import { Terminal, Package, Code, Zap } from 'lucide-react';
import CopyableCommand from './CopyableCommand';

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 }
};

const InstallationSection = ({ title, icon: Icon, iconColor, children }) => (
  <motion.div className="mb-12" {...fadeIn}>
    <h3 className="text-2xl font-semibold mb-4 flex items-center">
      <Icon className={`w-8 h-8 mr-2 ${iconColor}`} />
      {title}
    </h3>
    {children}
  </motion.div>
);

const Installation = () => {
  return (
    <section id="installation" className="py-20">
      <motion.h2 
        className="text-4xl font-bold mb-12 text-center text-blue-400"
        {...fadeIn}
      >
        Installation
      </motion.h2>
      <div className="max-w-4xl mx-auto px-4">
        <InstallationSection title="Install Fleem" icon={Terminal} iconColor="text-green-400">
          <p className="mb-4 text-gray-300 text-lg">To install Fleem globally, run:</p>
          <CopyableCommand command="npm install -g fleem" />
        </InstallationSection>

        <InstallationSection title="Quick Start" icon={Zap} iconColor="text-yellow-400">
          <p className="mb-4 text-gray-300 text-lg">Generate a new React project with default options:</p>
          <CopyableCommand command="fleem my-awesome-project --default" />
        </InstallationSection>

        <InstallationSection title="Customized Setup" icon={Package} iconColor="text-purple-400">
          <p className="mb-4 text-gray-300 text-lg">For a tailored setup, use the interactive CLI:</p>
          <CopyableCommand command="fleem my-custom-project" />
          <p className="mt-4 mb-4 text-gray-300 text-lg">The CLI will guide you through various options to configure your project.</p>
        </InstallationSection>

        <InstallationSection title="Advanced Configuration" icon={Code} iconColor="text-red-400">
          <p className="mb-4 text-gray-300 text-lg">Fine-tune your project with these options:</p>
          <ul className="list-disc list-inside mb-4 text-gray-300 text-lg grid grid-cols-1 md:grid-cols-2 gap-2">
            <li>--typescript</li>
            <li>--jest</li>
            <li>--prettier</li>
            <li>--scss</li>
            <li>--routing</li>
            <li>--state-management &lt;tool&gt;</li>
            <li>--package-manager &lt;manager&gt;</li>
          </ul>
          <p className="mb-4 text-gray-300 text-lg">Example of a fully customized setup:</p>
          <CopyableCommand command="fleem my-app --typescript --jest --prettier --scss --routing --state-management redux --package-manager yarn" />
        </InstallationSection>

        <InstallationSection title="Stay Updated" icon={Zap} iconColor="text-blue-400">
          <p className="mb-4 text-gray-300 text-lg">To upgrade Fleem to the latest version, run:</p>
          <CopyableCommand command="fleem upgrade" />
        </InstallationSection>
      </div>
    </section>
  );
};

export default Installation;