import React, { useState, useCallback, useMemo } from 'react';
import { Copy, CheckCircle } from 'lucide-react';

const CopyableCommand = React.memo(({ command }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(command).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  }, [command]);

  const buttonClassName = useMemo(() => {
    const baseClasses = "absolute top-2 right-2 text-white p-2 rounded-full transition-colors duration-200";
    return `${baseClasses} ${copied ? 'bg-blue-500' : 'hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500'}`;
  }, [copied]);

  return (
    <div className="relative bg-gray-900 rounded-lg overflow-hidden shadow-lg">
      <pre className="p-4 text-green-400 font-mono text-sm whitespace-pre-wrap break-words">{command}</pre>
      <button
        onClick={copyToClipboard}
        className={buttonClassName}
        aria-label="Copy command"
      >
        {copied ? <CheckCircle size={20} /> : <Copy size={20} />}
      </button>
    </div>
  );
});

CopyableCommand.displayName = 'CopyableCommand';

export default CopyableCommand;