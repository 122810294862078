import React, { useState, useEffect, useCallback } from 'react';
import { Terminal, Star } from 'lucide-react';
import { motion, useAnimation } from 'framer-motion';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const controls = useAnimation();

  const handleScroll = useCallback(() => {
    setIsScrolled(window.scrollY > 10);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    controls.start(isHovered ? "hover" : "initial");
  }, [isHovered, controls]);

  const variants = {
    header: {
      initial: { y: -100 },
      animate: { y: 0, transition: { duration: 0.5 } }
    },
    starButton: {
      initial: { scale: 1, rotate: 0 },
      hover: { scale: 1.1, rotate: [0, -10, 10, -10, 0], transition: { duration: 0.3 } },
      tap: { scale: 0.95 }
    },
    starIcon: {
      initial: { rotate: 0, fill: 'none', stroke: 'currentColor' },
      hover: { 
        rotate: 360, 
        fill: '#FFD700', 
        stroke: '#FFD700',
        transition: { duration: 0.5, ease: "easeInOut" } 
      }
    },
    starText: {
      hover: { y: [-2, 2, -2], transition: { duration: 0.5, repeat: Infinity, repeatType: "reverse" } }
    }
  };

  return (
    <motion.header 
      className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-gray-800 shadow-lg' : 'bg-transparent'}`}
      variants={variants.header}
      initial="initial"
      animate="animate"
    >
      <nav className="flex justify-between items-center px-4 py-4">
        <motion.div 
          className="flex items-center space-x-2"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <Terminal className="w-8 h-8 text-blue-400" />
          <span className="text-2xl font-bold text-white">Fleem</span>
        </motion.div>
        <motion.a
          href="https://github.com/adityavijay21/fleem"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center space-x-2 text-white bg-gray-700 hover:bg-gray-600 px-4 py-2 rounded-full transition-colors duration-200"
          variants={variants.starButton}
          initial="initial"
          whileHover="hover"
          whileTap="tap"
          onHoverStart={() => setIsHovered(true)}
          onHoverEnd={() => setIsHovered(false)}
          aria-label="Star Fleem on GitHub"
        >
          <motion.div
            variants={variants.starIcon}
            animate={controls}
          >
            <Star className="w-5 h-5" />
          </motion.div>
          <motion.span
            variants={variants.starText}
            animate={controls}
          >
            Star Fleem
          </motion.span>
        </motion.a>
      </nav>
      {isScrolled && (
        <div className="fixed top-16 w-full bg-gradient-to-r from-blue-500 to-purple-600 text-white text-center py-2 z-40">
          <a 
            href="https://marketplace.visualstudio.com/items?itemName=adityavijay21.ReactSnipp" 
            target="_blank" 
            rel="noopener noreferrer"
            className="hover:underline text-sm sm:text-base"
          >
            🚀 Download the ReactSnipp VS Code extension to supercharge your React development and more! Visit us today!
          </a>
        </div>
      )}
    </motion.header>
  );
};

export default React.memo(Header);