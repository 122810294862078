import React, { useState, useEffect, useMemo } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { ArrowRight, Terminal, Code, Package } from 'lucide-react';

const FULL_TEXT = 'Create customizable React projects with ease using this powerful CLI tool.';
const TYPING_INTERVAL = 50;

const iconComponents = [Terminal, Code, Package];

const Hero = () => {
  const [typedText, setTypedText] = useState('');
  const controls = useAnimation();

  useEffect(() => {
    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < FULL_TEXT.length) {
        setTypedText(prev => FULL_TEXT.slice(0, prev.length + 1));
        i++;
      } else {
        clearInterval(typingInterval);
        controls.start({ 
          scale: 1.1, 
          transition: { duration: 0.3, yoyo: Infinity, repeat: 3 } 
        });
      }
    }, TYPING_INTERVAL);

    return () => clearInterval(typingInterval);
  }, [controls]);

  const backgroundIcons = useMemo(() => (
    iconComponents.map((Icon, index) => (
      <motion.div
        key={index}
        initial={{ y: '100%' }}
        animate={{ y: '0%' }}
        transition={{ duration: 20, repeat: Infinity, ease: 'linear', delay: index * 2 }}
      >
        <Icon className="w-full h-full text-blue-300 opacity-20" />
      </motion.div>
    ))
  ), []);

  return (
    <section className="text-center py-20 relative overflow-hidden">
      <motion.div
        className="absolute inset-0 z-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.1 }}
        transition={{ duration: 2 }}
      >
        <div className="grid grid-cols-3 gap-4 h-full w-full">
          {backgroundIcons}
        </div>
      </motion.div>

      <motion.h1 
        className="text-6xl font-bold mb-4 relative z-10"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Fleem <motion.span animate={{ rotate: [0, 10, -10, 0] }} transition={{ duration: 2, repeat: Infinity }}>🚀</motion.span>
      </motion.h1>
      <motion.p 
        className="text-xl text-gray-300 mb-8 relative z-10"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {typedText}
      </motion.p>
      <motion.div
        className="relative z-10"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <motion.a 
          href="#installation" 
          className="bg-blue-500 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-blue-600 transition-colors duration-200 inline-flex items-center"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          animate={controls}
        >
          Get Started
          <ArrowRight className="ml-2" />
        </motion.a>
      </motion.div>
    </section>
  );
};

export default Hero;