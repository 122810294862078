import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { LineChart, Line, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import axios from 'axios';

const DownloadChart = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDownloads = useCallback(async () => {
    try {
      const startDate = '2024-09-23';
      const endDate = new Date().toISOString().split('T')[0];
      const response = await axios.get(`https://api.npmjs.org/downloads/range/${startDate}:${endDate}/fleem`);
      setData(response.data.downloads.map(item => ({
        date: new Date(item.day).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }),
        downloads: item.downloads
      })));
    } catch (error) {
      console.error('Error fetching download data:', error);
      setError('Failed to load data');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDownloads();
  }, [fetchDownloads]);

  const totalDownloads = useMemo(() => data.reduce((sum, item) => sum + item.downloads, 0), [data]);

  const renderTooltipContent = useCallback(({ payload, label }) => {
    if (payload && payload.length) {
      return (
        <div className="bg-gray-800 p-2 rounded">
          <p className="text-white">{`${payload[0].value} downloads`}</p>
          <p className="text-gray-400">{payload[0].payload.date}</p>
        </div>
      );
    }
    return null;
  }, []);

  if (isLoading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">{error}</div>;

  return (
    <div className="w-full mt-16">
      <h2 className="text-2xl font-bold mb-4 text-center text-blue-400">Downloads</h2>
      <div className="h-64 w-full">
        <ResponsiveContainer>
          <LineChart data={data} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" stroke="#555" />
            <YAxis stroke="#888" tick={{ fill: '#888', fontSize: 10 }} />
            <Tooltip content={renderTooltipContent} />
            <Line type="monotone" dataKey="downloads" stroke="#4FD1C5" strokeWidth={2} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <p className="mt-2 text-center text-sm text-gray-400">
        Total downloads: {totalDownloads.toLocaleString()}
      </p>
    </div>
  );
};

export default React.memo(DownloadChart);