import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

function Testimonials() {
  const testimonials = [
    { name: "Khush Vijay", role: "Frontend Developer", quote: "Fleem has revolutionized my React workflow. It's so easy to set up new projects now!" },
    { name: "Gaurav Sharma", role: "CEO @IntegratedByte", quote: "The customization options in Fleem are fantastic. It's become an essential tool in my development arsenal." },
    { name: "Vidhan Solanki", role: "Frontend Developer", quote: "As someone who works closely with developers, I love how Fleem standardizes our project setups. It's a game-changer!" },
    { name: "Priya Patel", role: "Full Stack Developer", quote: "Fleem's ability to integrate various tools seamlessly has significantly improved our team's productivity." },
    { name: "Rahul Gupta", role: "Senior Software Engineer", quote: "I'm impressed by how Fleem handles different state management options. It's truly versatile!" },
    { name: "Ananya Desai", role: "UX Designer", quote: "Fleem's project structure makes it so much easier for designers and developers to collaborate effectively." },
    { name: "Arjun Reddy", role: "Tech Lead", quote: "The time saved on project setup with Fleem allows us to focus more on building great features." },
    { name: "Neha Kapoor", role: "DevOps Engineer", quote: "Fleem's integration with various testing frameworks has greatly improved our CI/CD pipeline." },
    { name: "Vikram Malhotra", role: "Startup Founder", quote: "For rapid prototyping, Fleem is unbeatable. It's become an integral part of our development process." }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 3000);

    return () => clearInterval(timer);
  }, []);

  return (
    <section className="py-20 mt-1 w-full">
      <motion.h2 
        className="text-4xl font-bold mb-12 text-center text-blue-400"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        What Developers Are Saying
      </motion.h2>
      <div className="max-w-2xl mx-auto px-4">
        <AnimatePresence mode="wait">
          <motion.div 
            key={currentIndex}
            className="bg-gray-700 p-6 rounded-lg shadow-lg"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
          >
            <p className="text-gray-300 mb-4 text-lg italic">&ldquo;{testimonials[currentIndex].quote}&rdquo;</p>
            <div className="font-semibold text-white">{testimonials[currentIndex].name}</div>
            <div className="text-blue-400 text-sm">{testimonials[currentIndex].role}</div>
          </motion.div>
        </AnimatePresence>
      </div>
    </section>
  );
}

export default Testimonials;