import React from 'react';
import { ArrowRight, Zap } from 'lucide-react';
import { motion } from 'framer-motion';

const CallToAction = () => {
  const sectionVariants = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
  };

  const textVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  };

  const buttonVariants = {
    whileHover: { scale: 1.05 },
    whileTap: { scale: 0.95 },
  };

  const zapVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  };

  return (
    <motion.section 
      className="py-16 sm:py-24 text-center bg-gradient-to-br from-gray-900 via-gray-800 to-gray-700 rounded-xl shadow-2xl mx-4 sm:mx-8 my-12 sm:my-16 mt-36 sm:mt-36"
      initial="initial"
      animate="animate"
      transition={{ duration: 0.5 }}
      variants={sectionVariants}
    >
      <motion.h2 
        className="text-2xl sm:text-3xl font-extrabold mb-6 sm:mb-8 text-blue-400 px-4"
        initial="initial"
        animate="animate"
        transition={{ delay: 0.2, duration: 0.5 }}
        variants={textVariants}
      >
        Ready to Elevate Your React Development?
      </motion.h2>
      <motion.p 
        className="mb-8 sm:mb-10 text-lg sm:text-xl text-gray-300 px-4 max-w-2xl mx-auto"
        initial="initial"
        animate="animate"
        transition={{ delay: 0.4, duration: 0.5 }}
        variants={textVariants}
      >
        Harness the power of Fleem and craft extraordinary React projects that push the boundaries of innovation.
      </motion.p>
      <motion.a 
        href="https://www.npmjs.com/package/fleem" 
        className="bg-blue-600 text-white px-8 sm:px-10 py-3 sm:py-4 rounded-full text-lg sm:text-xl font-bold hover:bg-blue-500 transition-all duration-300 inline-flex items-center shadow-lg hover:shadow-xl transform hover:-translate-y-1"
        variants={buttonVariants}
      >
        Get Started Now
        <ArrowRight className="ml-2 w-5 h-5" />
      </motion.a>
      <motion.div 
        className="mt-12 flex justify-center items-center text-blue-400"
        initial="initial"
        animate="animate"
        transition={{ delay: 0.6, duration: 0.5 }}
        variants={zapVariants}
      >
        <Zap className="w-6 h-6 mr-2 text-yellow-400" />
        <span className="text-lg text-yellow-400">Join the elite community of Fleem developers</span>
      </motion.div>
    </motion.section>
  );
}

export default CallToAction;