import React from 'react';
import { Terminal, Package, Github } from 'lucide-react';
import { motion } from 'framer-motion';

const links = [
  { icon: <Package className="w-5 h-5" />, href: "https://www.npmjs.com/package/fleem", label: "npm" },
  { icon: <Github className="w-5 h-5" />, href: "https://github.com/adityavijay21/fleem", label: "GitHub" },
];

const motionProps = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
};

const FooterLink = ({ href, icon, label }) => (
  <a 
    href={href}
    className="text-gray-400 hover:text-white transition-colors duration-200 flex items-center space-x-2"
    aria-label={label}
  >
    {icon}
    <span>{label}</span>
  </a>
);

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-900 py-12">
      <div className="max-w-6xl mx-auto px-4">
        <motion.div {...motionProps} className="flex flex-col items-center mb-8">
          <div className="flex items-center space-x-2 mb-4">
            <Terminal className="w-8 h-8 text-blue-400" />
            <span className="text-2xl font-bold text-white">Fleem</span>
          </div>
          <motion.div {...motionProps} transition={{ delay: 0.2 }} className="mt-4">
            <a
              href="https://resxume.tech"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 bg-white text-gray-900 px-4 py-2 rounded-md hover:bg-gray-100 transition-colors duration-200"
            >
              <img src="https://media.licdn.com/dms/image/v2/D560BAQHGlilDsHxF6A/company-logo_100_100/company-logo_100_100/0/1703906237069?e=1735776000&v=beta&t=0qo7lFyPcB8VsVOYXz1BDly_5JOudriry7DBCDFn_9Y" alt="Resxume Logo" className="w-6 h-6" />
              <span>Backed by Resxume</span>
            </a>
          </motion.div>
        </motion.div>
        
        <motion.div 
          {...motionProps}
          transition={{ delay: 0.4 }}
          className="flex justify-center space-x-6 mb-8"
        >
          {links.map((link, index) => (
            <FooterLink key={index} {...link} />
          ))}
        </motion.div>
        
        <motion.div 
          {...motionProps}
          transition={{ delay: 0.6 }}
          className="text-center text-gray-500 text-sm"
        >
          © {currentYear} Fleem. All rights reserved. Made with ❤️ by @adityavijay21
        </motion.div>
      </div>
    </footer>
  );
};

export default Footer;